.contactScreen {
  display: flex;
  max-width: 972px;
  flex-direction: column;
}

.contactMobile {
  display: flex;
  flex-direction: column;
}

.section {
  margin-left: 32px;
}

.description {
  padding-right: 243px;
  white-space: pre-line;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147%;
  color: #424242;
}

.creators {
  margin-top: 32px;
}

.contactParagraph {
  margin-top: 40px;
  white-space: pre-line;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147%;
  color: #424242;
}

.contactParagraph a,
.creators a {
  color: #424242;
}

.creatorsMobile {
  list-style: none;
  margin: 32px 0 0 0;
  padding: 0;
}

.creators li,
.creatorsMobile li {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147%;
  color: #424242;
}

.formContainerMobile {
  margin-top: 64px;
  margin-bottom: 32px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 32px;
}

.article {
  white-space: pre-line;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 147%;
  color: #424242;
  margin-bottom: 64px;
}

.article a {
  color: #424242;
}

@media screen and (max-width: 1247px) {
  .contactScreen {
    max-width: 729px;
  }

  .formContainer {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .description {
    padding-right: 0;
    white-space: pre-line;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 147%;
    color: #424242;
  }
}

@media screen and (max-width: 1004px) {
  .contactScreen {
    max-width: 486px;
  }
}

@media screen and (max-width: 761px) {
  .contactScreen {
    max-width: 243px;
  }
}
