.layout {
  display: flex;
  justify-content: center;
  min-width: 320px;
}

.empty {
  height: 32px;
  width: 100%;
}

.emptyBottom {
  height: 32px;
  width: 100%;
}

.main {
  height: 100vh;
  margin-top: 72px;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
}
