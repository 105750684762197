.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  padding: 0 20px;
  z-index: 10;
  background-color: #f7f7f7;

  height: 72px;
}

.menuContainer {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
}

.openedMenu {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  transition: all 0.4s ease-in-out;
  z-index: 9;
}

.closedMenu {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
  z-index: 9;
  /* top: -237px; */
  border-bottom: 1px solid #e0e0e0;
  /*top: -262px;*/
  /*Each new item: -25px*/
  top: -287px;

}

.fixedWrapper {
  position: fixed;
}

.h1 > a {
  display: flex;
}

.menuButton {
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: -4px;
}

.logoLineMiddle {
  width: 16px;
  height: 2px;
  background-color: rgb(61, 61, 61);
  position: absolute;
  left: 4px;
  top: 11px;
  transition: all 0.4s ease-in-out;
}

.logoLineTop {
  width: 16px;
  height: 2px;
  background-color: rgb(61, 61, 61);
  position: absolute;
  left: 4px;
  top: 6px;
  transition: all 0.4s ease-in-out;
}

.logoLineBottom {
  width: 16px;
  height: 2px;
  background-color: rgb(61, 61, 61);
  position: absolute;
  left: 4px;
  top: 16px;
  transition: all 0.4s ease-in-out;
}

.nav1 {
  margin-top: 112px;
  padding-left: 20px;
}

.nav2 {
  margin-top: 28px;
  padding-left: 20px;
  margin-bottom: 28px;
}

.openedClickableContainer {
  /* height: calc(100vh - 309px); */
  height: calc(100vh - 334px);

  background-color: black;
  opacity: 0.2;
  transition: all 0.15s ease-in-out;
}
.closedClickableContainer {
  /* height: calc(100vh - 309px); */
  height: calc(100vh - 334px);

  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.layout {
  background-color: black;
  opacity: 0.2;
}

.list {
  list-style: none;
  margin: -8px 0 0 0;
  padding: 0;
}

.list li {
  margin-top: 8px;
}

.list li a {
  text-decoration: none;
  color: #424242;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  display: flex;
  transition: all 0.2s ease-in-out;
}

.list li a:hover {
  color: black;
  font-weight: 500;
  text-decoration: underline;
}

.logo {
  width: 94px;
  height: 24px;
}

.linkList {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 144px;
  justify-content: space-between;
}

.linkList li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkList li a {
  opacity: 0.78;
  display: flex;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.linkList li a:hover {
  opacity: 1;
}

.linkList li a img {
  width: 16px;
  height: 16px;
}

.illustration {
  right: 0;
  top: 101px;
  position: absolute;
  width: 166px;
  height: 140px;
}
