.link {
  width: 211px;
  margin-left: 32px;
  margin-bottom: 32px;
  overflow: hidden;
  text-decoration: none;
  color: #424242;
  transition: all 0.2s ease-in-out;
}

.linkMobile {
  margin-bottom: 32px;
  overflow: hidden;
  text-decoration: none;
  color: #424242;
  transition: all 0.2s ease-in-out;
  flex-basis: calc(50% - 15px);
  margin-left: 15px;
}

.link:hover {
  color: black;
  text-decoration: underline;
}

.link:hover .album {
  transform: scale(1.015);
}

.loader {
  position: "absolute";
}

.albumContainer {
  width: 211px;
  height: 211px;
  overflow: hidden;
}

.albumContainerMobile {
  overflow: hidden;
  display: flex;
}

.album {
  width: 211px;
  height: 211px;
  transition: all 0.2s ease-in-out;
}

.albumMobile {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
}

.artist {
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 8px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.albumName {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 2px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
