.iframe {
  border: 0;
  width: 100%;
  height: 42px;
  margin-top: 4px;
}

.playerContainer {
  position: relative;
  /* overflow: hidden; */
  display: flex;
}

.loaderContainer {
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 4px;
}
