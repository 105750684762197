.albumInfo {
  display: flex;
  max-width: 972px;
  flex-wrap: wrap-reverse;
  padding-bottom: 32px;
}

.section {
  width: 454px;
  margin-left: 32px;
}

.leftTitle {
  display: initial;
}

.rightTitle {
  display: none;
}

.h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
}

.h3 {
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
}

.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top: 32px;
  color: #424242;
}

.catalogue {
  margin-top: 4px;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147%;
  margin-top: 32px;
  white-space: pre-line;
  color: #424242;
}

.description a {
  color: #424242;
}

.social {
  margin-top: 32px;
}

.social ul {
  list-style: none;
  padding: 0;
  margin: -8px 0 0 0;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}

.social li {
  margin-right: 8px;
  margin-top: 8px;
}

.social ul li a {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.social ul li a:hover {
  color: black;
  text-decoration: underline;
}

.albumImage {
  width: 100%;
  min-width: 211px;
}

.tracklist {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
}

@media screen and (max-width: 1247px) {
  .albumInfo {
    max-width: 486px;
  }

  .leftTitle {
    display: none;
  }

  .rightTitle {
    display: initial;
  }

  .albumImage {
    margin-top: 32px;
  }
}
