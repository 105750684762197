.empty {
  height: 160px;
  width: 100%;
}

.homeScreen {
  max-width: 972px;
  display: flex;
  flex-wrap: wrap;
}

.homeMobile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -15px;
}

@media screen and (max-width: 1247px) {
  .homeScreen {
    max-width: 729px;
  }
}

@media screen and (max-width: 1004px) {
  .homeScreen {
    max-width: 486px;
  }
}

@media screen and (max-width: 761px) {
  .homeScreen {
    max-width: 243px;
  }
}
