.layout {
  display: flex;
  justify-content: center;
  padding-left: 32px;
  padding-right: 32px;
  min-width: 422px;
}

.empty {
  height: 137px;
  width: 100%;
}

.emptyBottom {
  height: 32px;
  width: 100%;
}

.main {
  height: 100vh;
  width: 972px;
  /* min-width: 211px; */
}

.headerContainer {
  position: fixed;
  height: 72px;
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
  z-index: 1;
  justify-content: center;
}

.header,
.header2 {
  height: 100%;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 32px;
  justify-content: space-between;
  width: 1183px;
}

.logo {
  width: 94px;
  height: 24px;
}

.linkList {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 144px;
  justify-content: space-between;
}

.linkList li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkList li a {
  opacity: 0.78;
  display: flex;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.linkList li a:hover {
  opacity: 1;
}

.linkList li a img {
  width: 17px;
  height: 16px;
}

.nav2 {
  margin-right: -4px;
}

@media screen and (max-width: 1247px) {
  .header {
    width: 940px;
  }

  .header2 {
    width: 697px;
  }

  .main {
    width: 729px;
  }
}

@media screen and (max-width: 1004px) {
  .header,
  .header2 {
    width: 697px;
  }

  .main {
    width: 486px;
  }
}

@media screen and (max-width: 761px) {
  .header,
  .header2 {
    width: 454px;
  }

  .main {
    width: 243px;
  }
}
