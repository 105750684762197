@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap);
body {
  background-color: #f7f7f7;
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Layout_layout__3OG7t {
  display: flex;
  justify-content: center;
  padding-left: 32px;
  padding-right: 32px;
  min-width: 422px;
}

.Layout_empty__LKYIC {
  height: 137px;
  width: 100%;
}

.Layout_emptyBottom__13TEm {
  height: 32px;
  width: 100%;
}

.Layout_main__1ABAU {
  height: 100vh;
  width: 972px;
  /* min-width: 211px; */
}

.Layout_headerContainer__2kepG {
  position: fixed;
  height: 72px;
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
  z-index: 1;
  justify-content: center;
}

.Layout_header__1kwmh,
.Layout_header2__3FcdR {
  height: 100%;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 32px;
  justify-content: space-between;
  width: 1183px;
}

.Layout_logo__24ZPQ {
  width: 94px;
  height: 24px;
}

.Layout_linkList__1afjV {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 144px;
  justify-content: space-between;
}

.Layout_linkList__1afjV li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Layout_linkList__1afjV li a {
  opacity: 0.78;
  display: flex;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.Layout_linkList__1afjV li a:hover {
  opacity: 1;
}

.Layout_linkList__1afjV li a img {
  width: 17px;
  height: 16px;
}

.Layout_nav2__23K-w {
  margin-right: -4px;
}

@media screen and (max-width: 1247px) {
  .Layout_header__1kwmh {
    width: 940px;
  }

  .Layout_header2__3FcdR {
    width: 697px;
  }

  .Layout_main__1ABAU {
    width: 729px;
  }
}

@media screen and (max-width: 1004px) {
  .Layout_header__1kwmh,
  .Layout_header2__3FcdR {
    width: 697px;
  }

  .Layout_main__1ABAU {
    width: 486px;
  }
}

@media screen and (max-width: 761px) {
  .Layout_header__1kwmh,
  .Layout_header2__3FcdR {
    width: 454px;
  }

  .Layout_main__1ABAU {
    width: 243px;
  }
}

.Header_header__SZfdb {
  width: 211px;
  min-width: 211px;
  display: flex;
  flex-direction: column;
}

.Header_fixedWrapper__1elK9 {
  position: fixed;
}

.Header_h1__Y2jKu {
  margin: 64px 0 0 0;
}

.Header_nav1__2Hrga {
  margin-top: 145px;
}

.Header_nav2__3MEx2 {
  margin-top: 28px;
}

.Header_list__3HlrR {
  list-style: none;
  margin: -8px 0 0 0;
  padding: 0;
}

.Header_list__3HlrR li {
  margin-top: 8px;
}

.Header_list__3HlrR li a {
  text-decoration: none;
  color: #424242;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  display: flex;
  transition: all 0.2s ease-in-out;
}

.Header_list__3HlrR li a:hover {
  color: black;
  font-weight: 500;
  text-decoration: underline;
}

.Header_linkList__1eUrN {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 144px;
  justify-content: space-between;
}

.Header_linkList__1eUrN li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header_linkList__1eUrN li a {
  opacity: 0.78;
  display: flex;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.Header_linkList__1eUrN li a:hover {
  opacity: 1;
}

.Header_linkList__1eUrN li a img {
  width: 16px;
  height: 16px;
}

.Header_illustration__12LXN {
  position: fixed;
  bottom: 0;
  width: 200px;
  height: 237px;
}

.Header_illustration__12LXN img {
  margin-left: -32px;
}

.MobileLayout_layout__2Au8p {
  display: flex;
  justify-content: center;
  min-width: 320px;
}

.MobileLayout_empty__1n3gc {
  height: 32px;
  width: 100%;
}

.MobileLayout_emptyBottom__1vP14 {
  height: 32px;
  width: 100%;
}

.MobileLayout_main__1dpUO {
  height: 100vh;
  margin-top: 72px;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
}

.MobileHeader_header__3ScrL {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  padding: 0 20px;
  z-index: 10;
  background-color: #f7f7f7;

  height: 72px;
}

.MobileHeader_menuContainer__1VZ0K {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
}

.MobileHeader_openedMenu__1M3OX {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  transition: all 0.4s ease-in-out;
  z-index: 9;
}

.MobileHeader_closedMenu__3h6ug {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
  z-index: 9;
  /* top: -237px; */
  border-bottom: 1px solid #e0e0e0;
  /*top: -262px;*/
  /*Each new item: -25px*/
  top: -287px;

}

.MobileHeader_fixedWrapper__1QuB1 {
  position: fixed;
}

.MobileHeader_h1__2WQmD > a {
  display: flex;
}

.MobileHeader_menuButton__jBaa9 {
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: -4px;
}

.MobileHeader_logoLineMiddle__3tY9e {
  width: 16px;
  height: 2px;
  background-color: rgb(61, 61, 61);
  position: absolute;
  left: 4px;
  top: 11px;
  transition: all 0.4s ease-in-out;
}

.MobileHeader_logoLineTop__3zCVN {
  width: 16px;
  height: 2px;
  background-color: rgb(61, 61, 61);
  position: absolute;
  left: 4px;
  top: 6px;
  transition: all 0.4s ease-in-out;
}

.MobileHeader_logoLineBottom__sx7lt {
  width: 16px;
  height: 2px;
  background-color: rgb(61, 61, 61);
  position: absolute;
  left: 4px;
  top: 16px;
  transition: all 0.4s ease-in-out;
}

.MobileHeader_nav1__snETr {
  margin-top: 112px;
  padding-left: 20px;
}

.MobileHeader_nav2__2m8zp {
  margin-top: 28px;
  padding-left: 20px;
  margin-bottom: 28px;
}

.MobileHeader_openedClickableContainer__24bQN {
  /* height: calc(100vh - 309px); */
  height: calc(100vh - 334px);

  background-color: black;
  opacity: 0.2;
  transition: all 0.15s ease-in-out;
}
.MobileHeader_closedClickableContainer__1XfRt {
  /* height: calc(100vh - 309px); */
  height: calc(100vh - 334px);

  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.MobileHeader_layout__1fPm4 {
  background-color: black;
  opacity: 0.2;
}

.MobileHeader_list__1etRq {
  list-style: none;
  margin: -8px 0 0 0;
  padding: 0;
}

.MobileHeader_list__1etRq li {
  margin-top: 8px;
}

.MobileHeader_list__1etRq li a {
  text-decoration: none;
  color: #424242;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  display: flex;
  transition: all 0.2s ease-in-out;
}

.MobileHeader_list__1etRq li a:hover {
  color: black;
  font-weight: 500;
  text-decoration: underline;
}

.MobileHeader_logo__aGhfl {
  width: 94px;
  height: 24px;
}

.MobileHeader_linkList__1V98d {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 144px;
  justify-content: space-between;
}

.MobileHeader_linkList__1V98d li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileHeader_linkList__1V98d li a {
  opacity: 0.78;
  display: flex;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.MobileHeader_linkList__1V98d li a:hover {
  opacity: 1;
}

.MobileHeader_linkList__1V98d li a img {
  width: 16px;
  height: 16px;
}

.MobileHeader_illustration__1E6C9 {
  right: 0;
  top: 101px;
  position: absolute;
  width: 166px;
  height: 140px;
}

.AlbumInfo_albumInfo__81Lpz {
  display: flex;
  max-width: 972px;
  flex-wrap: wrap-reverse;
  padding-bottom: 32px;
}

.AlbumInfo_section__1ujET {
  width: 454px;
  margin-left: 32px;
}

.AlbumInfo_leftTitle__XrSYg {
  display: initial;
}

.AlbumInfo_rightTitle__3gKty {
  display: none;
}

.AlbumInfo_h2__1DO87 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
}

.AlbumInfo_h3__3iAXu {
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
}

.AlbumInfo_subtitle__UhAba {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top: 32px;
  color: #424242;
}

.AlbumInfo_catalogue__G5-V0 {
  margin-top: 4px;
}

.AlbumInfo_description__PY7zc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147%;
  margin-top: 32px;
  white-space: pre-line;
  color: #424242;
}

.AlbumInfo_description__PY7zc a {
  color: #424242;
}

.AlbumInfo_social__1Wp8E {
  margin-top: 32px;
}

.AlbumInfo_social__1Wp8E ul {
  list-style: none;
  padding: 0;
  margin: -8px 0 0 0;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}

.AlbumInfo_social__1Wp8E li {
  margin-right: 8px;
  margin-top: 8px;
}

.AlbumInfo_social__1Wp8E ul li a {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.AlbumInfo_social__1Wp8E ul li a:hover {
  color: black;
  text-decoration: underline;
}

.AlbumInfo_albumImage__1s7Xt {
  width: 100%;
  min-width: 211px;
}

.AlbumInfo_tracklist__2LoES {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
}

@media screen and (max-width: 1247px) {
  .AlbumInfo_albumInfo__81Lpz {
    max-width: 486px;
  }

  .AlbumInfo_leftTitle__XrSYg {
    display: none;
  }

  .AlbumInfo_rightTitle__3gKty {
    display: initial;
  }

  .AlbumInfo_albumImage__1s7Xt {
    margin-top: 32px;
  }
}

.BandcampPlayer_iframe__2j8Sl {
  border: 0;
  width: 100%;
  height: 42px;
  margin-top: 4px;
}

.BandcampPlayer_playerContainer__17uoX {
  position: relative;
  /* overflow: hidden; */
  display: flex;
}

.BandcampPlayer_loaderContainer__3prDT {
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 4px;
}

.SoundcloudPlayer_container__Wjq-J {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #d2d2d2;
  width: 100%;
  height: 40px;
  background-color: white;
}

.AlbumCard_link__zxLux {
  width: 211px;
  margin-left: 32px;
  margin-bottom: 32px;
  overflow: hidden;
  text-decoration: none;
  color: #424242;
  transition: all 0.2s ease-in-out;
}

.AlbumCard_linkMobile__--a_B {
  margin-bottom: 32px;
  overflow: hidden;
  text-decoration: none;
  color: #424242;
  transition: all 0.2s ease-in-out;
  flex-basis: calc(50% - 15px);
  margin-left: 15px;
}

.AlbumCard_link__zxLux:hover {
  color: black;
  text-decoration: underline;
}

.AlbumCard_link__zxLux:hover .AlbumCard_album__2elxh {
  -webkit-transform: scale(1.015);
          transform: scale(1.015);
}

.AlbumCard_loader__1Gy5m {
  position: "absolute";
}

.AlbumCard_albumContainer__2PVnt {
  width: 211px;
  height: 211px;
  overflow: hidden;
}

.AlbumCard_albumContainerMobile__3A9Qw {
  overflow: hidden;
  display: flex;
}

.AlbumCard_album__2elxh {
  width: 211px;
  height: 211px;
  transition: all 0.2s ease-in-out;
}

.AlbumCard_albumMobile__2LsGY {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
}

.AlbumCard_artist__1dg1O {
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 8px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AlbumCard_albumName__4Nvbc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 2px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Discography_discographyContainer__5XN6I {
  margin-top: 32px;
}

.Discography_discographyContainerMobile__1X0Cy {
  /* margin: 32px 20px 0 20px; */
  margin-top: 32px;
}

.Discography_discographyTitle__19ljr {
  margin-left: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.Discography_discographyTitleMobile__2csUK {
  /* margin-left: 32px; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.Discography_discography__2R9An {
  margin-top: 32px;
  max-width: 972px;
  display: flex;
  flex-wrap: wrap;
}

.Discography_discographyMobile__2VfCD {
  margin-top: 32px;
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
}

@media screen and (max-width: 1247px) {
  .Discography_discography__2R9An {
    max-width: 486px;
  }
}

@media screen and (max-width: 761px) {
  .Discography_discography__2R9An {
    max-width: 243px;
  }
}

.AlbumInfoMobile_albumInfo__1_z-8 {
  display: flex;
  flex-direction: column;
}

.AlbumInfoMobile_section__2UuVa {
  width: 454px;
  margin-left: 32px;
}

.AlbumInfoMobile_leftTitle__3edpR {
  margin-top: 32px;
  display: initial;
}

.AlbumInfoMobile_rightTitle__zwXRi {
  display: none;
}

.AlbumInfoMobile_h2__M2uD6 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
}

.AlbumInfoMobile_h3__2c93x {
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
}

.AlbumInfoMobile_subtitle__3PIS4 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top: 32px;
  color: #424242;
}

.AlbumInfoMobile_catalogue__EWL0B {
  margin-top: 4px;
}

.AlbumInfoMobile_description__vafki {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147%;
  margin-top: 32px;
  white-space: pre-line;
  color: #424242;
}

.AlbumInfoMobile_social__8b-xm {
  margin-top: 32px;
}

.AlbumInfoMobile_social__8b-xm ul {
  list-style: none;
  padding: 0;
  margin: -8px 0 0 0;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}

.AlbumInfoMobile_social__8b-xm li {
  margin-top: 8px;
  margin-right: 8px;
}

.AlbumInfoMobile_social__8b-xm ul li a {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.AlbumInfoMobile_social__8b-xm ul li a:hover {
  color: black;
  text-decoration: underline;
}

.AlbumInfoMobile_albumImage__1vnv2 {
  width: 100%;
  height: 100%;
}

.AlbumInfoMobile_tracklist__2gPJw {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 32px;
}

.contact_contactScreen__3VWlw {
  display: flex;
  max-width: 972px;
  flex-direction: column;
}

.contact_contactMobile__c2w6H {
  display: flex;
  flex-direction: column;
}

.contact_section__3vScP {
  margin-left: 32px;
}

.contact_description__rEMOU {
  padding-right: 243px;
  white-space: pre-line;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147%;
  color: #424242;
}

.contact_creators__p0mXt {
  margin-top: 32px;
}

.contact_contactParagraph__3-n2j {
  margin-top: 40px;
  white-space: pre-line;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147%;
  color: #424242;
}

.contact_contactParagraph__3-n2j a,
.contact_creators__p0mXt a {
  color: #424242;
}

.contact_creatorsMobile__1Zb96 {
  list-style: none;
  margin: 32px 0 0 0;
  padding: 0;
}

.contact_creators__p0mXt li,
.contact_creatorsMobile__1Zb96 li {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147%;
  color: #424242;
}

.contact_formContainerMobile__50_G0 {
  margin-top: 64px;
  margin-bottom: 32px;
}

.contact_title__IViJp {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 32px;
}

.contact_article__2XVQr {
  white-space: pre-line;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 147%;
  color: #424242;
  margin-bottom: 64px;
}

.contact_article__2XVQr a {
  color: #424242;
}

@media screen and (max-width: 1247px) {
  .contact_contactScreen__3VWlw {
    max-width: 729px;
  }

  .contact_formContainer__OV2QV {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .contact_description__rEMOU {
    padding-right: 0;
    white-space: pre-line;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 147%;
    color: #424242;
  }
}

@media screen and (max-width: 1004px) {
  .contact_contactScreen__3VWlw {
    max-width: 486px;
  }
}

@media screen and (max-width: 761px) {
  .contact_contactScreen__3VWlw {
    max-width: 243px;
  }
}

.home_empty__8Rrol {
  height: 160px;
  width: 100%;
}

.home_homeScreen__2XNDg {
  max-width: 972px;
  display: flex;
  flex-wrap: wrap;
}

.home_homeMobile__2Os90 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -15px;
}

@media screen and (max-width: 1247px) {
  .home_homeScreen__2XNDg {
    max-width: 729px;
  }
}

@media screen and (max-width: 1004px) {
  .home_homeScreen__2XNDg {
    max-width: 486px;
  }
}

@media screen and (max-width: 761px) {
  .home_homeScreen__2XNDg {
    max-width: 243px;
  }
}

