.header {
  width: 211px;
  min-width: 211px;
  display: flex;
  flex-direction: column;
}

.fixedWrapper {
  position: fixed;
}

.h1 {
  margin: 64px 0 0 0;
}

.nav1 {
  margin-top: 145px;
}

.nav2 {
  margin-top: 28px;
}

.list {
  list-style: none;
  margin: -8px 0 0 0;
  padding: 0;
}

.list li {
  margin-top: 8px;
}

.list li a {
  text-decoration: none;
  color: #424242;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  display: flex;
  transition: all 0.2s ease-in-out;
}

.list li a:hover {
  color: black;
  font-weight: 500;
  text-decoration: underline;
}

.linkList {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 144px;
  justify-content: space-between;
}

.linkList li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkList li a {
  opacity: 0.78;
  display: flex;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.linkList li a:hover {
  opacity: 1;
}

.linkList li a img {
  width: 16px;
  height: 16px;
}

.illustration {
  position: fixed;
  bottom: 0;
  width: 200px;
  height: 237px;
}

.illustration img {
  margin-left: -32px;
}
