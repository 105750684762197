.discographyContainer {
  margin-top: 32px;
}

.discographyContainerMobile {
  /* margin: 32px 20px 0 20px; */
  margin-top: 32px;
}

.discographyTitle {
  margin-left: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.discographyTitleMobile {
  /* margin-left: 32px; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.discography {
  margin-top: 32px;
  max-width: 972px;
  display: flex;
  flex-wrap: wrap;
}

.discographyMobile {
  margin-top: 32px;
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
}

@media screen and (max-width: 1247px) {
  .discography {
    max-width: 486px;
  }
}

@media screen and (max-width: 761px) {
  .discography {
    max-width: 243px;
  }
}
